import { BsTelegram, BsTwitterX } from "react-icons/bs";
import { SiEthereum, SiGitbook } from "react-icons/si";
import "./Footer.scss";

const Footer = ({
  FooterPattern,
  FooterLogo,
  text = "",
  IcoName,
  TextFooter,
  dataContact,
}) => {
  return (
    <footer
      className="footer-area bg-img"
      style={{ backgroundImage: `url(${FooterPattern})` }}
    >
      {/* <Contact dataContact={dataContact} /> */}
      <div className="footer-content-area addMLInSmall">
        <div className="container">
          <div className="social_media">
            <a
              className="social_media-item"
              href="https://twitter.com/TrackerAI_ERC"
              target="_blank"
              rel="noreferrer"
              title="Tracker AI Twitter"
              aria-label="Tracker AI Twitter"
            >
              <BsTwitterX />
            </a>
            <a
              className="social_media-item"
              href="https://t.me/TrackerAI_ERC"
              target="_blank"
              rel="noreferrer"
              title="Tracker AI Telegram"
              aria-label="Tracker AI Telegram"
            >
              <BsTelegram />
            </a>

            <a
              className="social_media-item"
              href="https://tracker-ai.gitbook.io/tracker-ai-whitepaper/"
              target="_blank"
              rel="noreferrer"
              title="Tracker AI Gitbook"
              aria-label="Tracker AI Gitbook"
            >
              <SiGitbook />
            </a>
            <a
              className="social_media-item"
              href="https://etherscan.io/address/0xda558a295e8d2c1438f7b4806e283940dec7e6db"
              target="_blank"
              rel="noreferrer"
              title="Tracker AI Etherscan"
              aria-label="Tracker AI Etherscan"
            >
              <SiEthereum />
            </a>
          </div>
          {/* bottom */}
          <section className="social_media">
            <small className="mb-2 block text-xs">
              &copy;{" "}
              {`${new Date().getFullYear()} Tracker AI. All rights reserved.`}
            </small>
          </section>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
