/* eslint-disable jsx-a11y/anchor-is-valid */
import "./AboutUs.scss";

const AboutUs = ({
  ClassSec = "about-us-area section-padding-0-100 addMLInSmall clearfix",
  Computer1,
  Trade,
  titleUp,
  titleDown,
  textUp,
  textDown,
  list,
  link,
  linkHref,
}) => {
  return (
    <section className={ClassSec} id="usecases">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6 offset-lg-0 col-md-12 no-padding-left">
            <div className="welcome-meter">
              <img
                draggable="false"
                className="comp-img"
                src={Computer1}
                alt=""
              />
              <img
                draggable="false"
                className="supportImg floating-anim"
                src={Trade}
                alt=""
              />
            </div>
          </div>
          <div className="col-12 col-lg-6 offset-lg-0 mt-s">
            <div className="who-we-contant">
              <div className="dream-dots text-left fadeInUp" data-aos="fade-up">
                <span className="gradient-text">{titleUp}</span>
              </div>
              <h4 className="fadeInUp" data-aos="fade-up">
                {titleDown}
              </h4>
              <div>
                {list.map((item, index) => (
                  <p
                    className="fadeInUp"
                    key={index + "about-list"}
                    data-aos="fade-up"
                  >
                    &bull; {item}
                  </p>
                ))}
              </div>
              {/* <p className="fadeInUp" data-aos="fade-up">{textUp}</p>
              <p className="fadeInUp" data-aos="fade-up">{textDown}</p> */}  
              <a
                className="btn more-btn mt-15 fadeInUp"
                data-aos="fade-up"
                href={linkHref}
                target="_blank"
                rel="noreferrer"
              >
                {link}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
