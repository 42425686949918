import { useState } from "react";
import "./Wellcome.scss";

const SecHeroSection = ({
  ClassSec = "",
  ColClass = "",
  specialHead = "",
  textUp = "",
  textDown = "",
  textDown1 = "",
  textDown2 = "",
  link1 = "",
  link1href = "",
  link2 = "",
  link2href = "",
  img = "",
  HomeDemo2_3 = false,
  HomeDemo3 = false,
}) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <section className={ClassSec} id="overview">
      {/* Hero Content */}
      <div className="hero-section-content">
        <div className="container ">
          <div className="row align-items-center">
            {/* Welcome Content */}
            <div className={ColClass}>
              <div className="welcome-content">
                <div className="promo-section">
                  <h3 className="special-head">{specialHead}</h3>
                </div>
                <h1 data-wow-delay="0.2s">{textUp}</h1>
                <p className="w-text">
                  {textDown}{" "}
                  {showMore ? null : (
                    <span
                      className="show-more-btn"
                      onClick={() => setShowMore(true)}
                    >
                      Show More
                    </span>
                  )}
                </p>
                {showMore ? (
                  <>
                    <p className="w-text">{textDown1}</p>
                    <p className="w-text">
                      {textDown2}{" "}
                      {!showMore ? null : (
                        <span
                          className="show-more-btn"
                          onClick={() => setShowMore(false)}
                        >
                          Show Less
                        </span>
                      )}
                    </p>
                  </>
                ) : null}
                <div className="dream-btn-group" data-wow-delay="0.4s">
                  <a
                    href={link1href}
                    className="btn more-btn mr-3 addMarBtnLink"
                  >
                    {link1}
                  </a>
                  <a href={link2href} className="btn more-btn">
                    {link2}
                  </a>
                </div>
              </div>
            </div>
            {HomeDemo2_3 && (
              <div className="col-lg-6">
                {HomeDemo3 && (
                  <div className="illusto-2">
                    <img draggable="false" src={img} alt="" />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SecHeroSection;
