import {
  HomeDemo1Icons1,
  HomeDemo1Icons2,
  HomeDemo1Icons3,
  HomeDemo1Partners1,
  HomeDemo1Partners2,
  HomeDemo1Partners3,
  HomeDemo1Partners4,
  HomeDemo1Partners5,
  HomeDemo1Partners6,
  HomeDemo1TeamImg1,
  HomeDemo1TeamImg2,
  HomeDemo1TeamImg3,
  HomeDemo1TeamImg4,
} from "../../../utils/allImgs";

export const SecPartnersContent = [
  {
    img: HomeDemo1Partners2,
  },
  {
    img: HomeDemo1Partners1,
  },
  {
    img: HomeDemo1Partners3,
  },
  {
    img: HomeDemo1Partners4,
  },
  {
    img: HomeDemo1Partners5,
  },
  {
    img: HomeDemo1Partners6,
  },
];

export const HowToStartInfo = [
  {
    img: HomeDemo1Icons1,
    num: 1,
    title: "Portfolio Tracking and Management",
    text: "Monitor all the coins/tokens across multiple wallets and various blockchains effortlessly. Customize your portfolio view by choosing to display tokens that meet specific criteria, such as a minimum value threshold— for example, showing only tokens valued above $10. This approach helps streamline your overview and eliminates clutter from spam tokens.",
  },
  {
    img: HomeDemo1Icons2,
    num: 2,
    title: "Wallet Manager",
    text: 'Organize your wallets as "My Wallet" or "Stalked Wallet" with Tracker AI\'s user-friendly interface, enabling easy addition, renaming, and deletion. No need to import private keys or seed phrases; simply provide the wallet address. The same applies to any wallet you wish to monitor.',
  },
  {
    img: HomeDemo1Icons3,
    num: 3,
    title: "KOLs’ Portfolio Tracking",
    text: "Keep tabs on Key Opinion Leaders' holdings across multiple chains by inputting their wallet addresses. You can also manage monitored wallets within the bot's wallet manager.",
  },
];

export const OurTeamInfo = [
  {
    img: HomeDemo1TeamImg1,
    title: "Joman Helal",
    text: "Executive Officer",
  },
  {
    img: HomeDemo1TeamImg2,
    title: "Slans Alons",
    text: "Business Development",
  },
  {
    img: HomeDemo1TeamImg3,
    title: "Josha Michal",
    text: "UX/UI Designer",
  },
  {
    img: HomeDemo1TeamImg4,
    title: "Johan Wright",
    text: "Head of Marketing",
  },
];
