import { useEffect } from "react";
import { addRemoveClassBody } from "../../utils";

import { HowToStartInfo } from "../../data/data-containers/HomeDemo1/data-HomeDemo1.js";

import RoadmapInfo from "../../data/data-containers/HomeDemo1/data-RoadmapInfo.json";
import dataTokenInfoDown from "../../data/data-containers/HomeDemo1/data-TokenInfoDown.json";
import dataTokenInfoUp from "../../data/data-containers/HomeDemo1/data-TokenInfoUp.json";
import dataContact from "../../data/data-containers/HomeDemo1/data-contact.json";
import IcoName from "../../data/data-layout/Footer/data-IcoName.json";
import TextFooter from "../../data/data-layout/Footer/data-TextFooter.json";

import {
  FooterLogo,
  FooterPattern,
  HomeDemo1Computer1,
  HomeDemo1Dist,
  HomeDemo1Trade,
} from "../../utils/allImgs";

import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";

import AboutUs from "../../components/AboutUs";
import SecHeroSection from "../../components/HeroSection";
import HowToStart from "../../components/HowToStart";
import Roadmap from "../../components/Roadmap";
import TokenInfo from "../../components/TokenInfo";

const HomeDemo1 = () => {
  useEffect(() => {
    addRemoveClassBody("darker");
  }, []);

  return (
    <div>
      <Header Title="Tracker AI | Your advanced Portfolio Tracker" />
      <SecHeroSection
        ClassSec="hero-section app section-padding"
        ColClass="col-12 col-lg-6 col-md-12"
        specialHead="Transform Your Crypto Portfolio Tracking"
        textUp="The ultimate solution for managing your multi-chain portfolio effortlessly"
        textDown="Tracker AI is the ultimate multi-chain portfolio tracking solution. Simply paste your wallet address into the Telegram bot, and you'll instantly access all the tokens within your wallet. The days of wondering which wallet holds which token are behind us. Tracker AI displays all your tokens and their respective values."
        textDown1="Moreover, this integrated AI tracker assists in monitoring the performance of coins in your portfolio, offering recommendations on when to capitalize on profits or mitigate losses. Currently, Tracker AI supports ETH, BSC, Polygon, ARB, Fantom, and Base, with additional chains on the horizon."
        textDown2="As a Veteran Degen, you likely manage a multitude of wallets for your trading endeavors. It's not uncommon to overlook tokens stashed away in one of them until their value plummets. Tracker AI swoops in to rescue you from such oversights. This revolutionary tool consolidates all your tokens across various blockchains, along with their respective values, into one convenient interface. Say goodbye to token amnesia and hello to seamless portfolio management!"
        link1="Tracker AI Bot"
        link1href="https://t.me/OxTracker_AI_Bot"
        link2="Telegram Community"
        link2href="https://t.me/TrackerAI_ERC"
      />
      <div className="clearfix" />
      <HowToStart data={HowToStartInfo} />
      <AboutUs
        Computer1={HomeDemo1Computer1}
        Trade={HomeDemo1Trade}
        titleUp="Multi-Chain Portfolio Tracking"
        titleDown="Tracker AI use cases"
        list={[
          "Tracker AI Bot offers comprehensive portfolio tracking capabilities, allowing you to effectively monitor your investments.",
          "With Tracker AI Bot, you can conveniently consolidate and assess the total value of your tokens across multiple wallets and blockchains.",
          "Utilize Tracker AI Bot to monitor other individuals' wallets, enabling you to track the portfolios of your favorite Key Opinion Leaders (KOLs).",
          "Additionally, Tracker AI Bot supports usage in both public and private groups, facilitating collective wallet monitoring among group members.",
          "For those keen on flaunting their impressive portfolios, this bot is your ideal companion. Easily showcase your portfolio by forwarding the message from the bot to different private groups or channels.",
          "Our integrated AI assists in monitoring the performance of your coins and provides recommendations on whether to capitalize on profits or mitigate losses.",
        ]}
        link="Read More"
        linkHref="https://tracker-ai.gitbook.io/tracker-ai-whitepaper/"
      />

      <Roadmap
        data={RoadmapInfo}
        ClassSpanTitle="roadmap section-padding-0-0"
      />

      <TokenInfo
        img={HomeDemo1Dist}
        dataUp={dataTokenInfoUp}
        dataDown={dataTokenInfoDown}
      />

      <Footer
        FooterPattern={FooterPattern}
        FooterLogo={FooterLogo}
        text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Velit ducimus voluptatibus neque illo id repellat quisquam? Autem expedita earum quae laborum ipsum ad."
        IcoName={IcoName}
        TextFooter={TextFooter}
        dataContact={dataContact}
      />
    </div>
  );
};

export default HomeDemo1;
