import SectionHeading from "../SectionHeading";
import "./Roadmap.scss";

const Roadmap = ({ data, ClassSpanTitle }) => {
  return (
    <section className={`${ClassSpanTitle} addMLInSmall`} id="roadmap">
      <SectionHeading
        ClassSpanTitle="gradient-text blue"
        titleUp="The road ahead"
        titleDown="ROADMAP"
        // text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis accumsan nisi Ut ut felis congue nisl hendrerit commodo."
      />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="main-timeline">
              {data &&
                data.map((item, key) => (
                  <div className="timeline" key={key}>
                    <div className="icon" />
                    <div className="date-content">
                      <div className="date-outer">
                        {" "}
                        <span className="date">
                          {" "}
                          <span className="month">{item.month}</span>{" "}
                          <span className="year">{item.year}</span>{" "}
                        </span>
                      </div>
                    </div>
                    <div className="timeline-content">
                      {/* <h5 className="title">{item.title}</h5> */}
                      {item.list.map((item, index) => (
                        <p
                          className="description text-light-gray"
                          key={index + "roadmap-item"}
                        >
                          {" "}
                          - {item}{" "}
                        </p>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
