import SectionHeading from "../SectionHeading";

const HowToStart = ({ data }) => {
  return (
    <section
      className="darky how section-padding-100-70 addMLInSmall"
      id="features"
    >
      <div className="container">
        <SectionHeading
          ClassSpanTitle="gradient-t green"
          titleUp="AI Tracker Features"
          titleDown="How it works"
          // text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis accumsan nisi Ut ut felis congue nisl hendrerit commodo."
        />
        <div className="row">
          {data &&
            data.map((item, key) => (
              <div className="col-12 col-md-6 col-lg-4" key={key}>
                {/* Content */}
                <div
                  className="service_single_content box-shadow text-center mb-100"
                  data-aos="fade-up"
                >
                  {/* Icon */}
                  <div className="service_icon">
                    <img
                      draggable="false"
                      src={item.img}
                      className="white-icon"
                      alt=""
                    />
                    <span className="step-num">{item.num}</span>
                  </div>
                  <h6>{item.title}</h6>
                  <p>{item.text}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default HowToStart;
